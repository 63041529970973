import React from "react"
import Helmet from "react-helmet"
import Layout from "../../components/layout"
import ContactUsForm from "../../components/contact-us-form"
import { Link } from "gatsby"
import profilePhoto from "../../images/greg-white.jpg"
import PageHeader from "../../components/page-header"
import { Row, Col, Alert } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrophy } from '@fortawesome/free-solid-svg-icons'

export default () => (
	<Layout style={{ color: `teal` }}>
		<Helmet title="Gregory White | RWK Attorneys" defer={false}>
			<script type="application/ld+json">{JSON.stringify({
				"@context": "http://schema.org/",
				"@type": "Person",
				"name": `Greg White`,
				"jobTitle": "Criminal & Family Law Attorney",
				"telephone": "12058332589",
				"url": "https://rwkattorneys.com/attorneys/greg-white"
			})}</script>
		</Helmet>

		<PageHeader>
			<Row>
				<Col>
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb">
							<li class="breadcrumb-item"><Link to="/">Home</Link></li>
							<li class="breadcrumb-item"><Link to="/attorneys">Attorneys</Link></li>
							<li class="breadcrumb-item active" aria-current="page">Greg White</li>
						</ol>
					</nav>
					<h1>Greg White</h1>
					<p className="lead text-muted">Partner</p>
				</Col>
			</Row>
		</PageHeader>


		<div className="container mt-3">

			<div className="row">
				<div className="col-md-8">
					<Alert variant="info" className="d-flex justify-content-around align-items-center">
						<FontAwesomeIcon icon={faTrophy} className="fa-lg m-3" />&nbsp;
						<p className="mb-0">Recognized as one of Birmingham's Top Attorneys in Divorce by Birmingham Magazine!</p>
					</Alert>
					<img src={profilePhoto} className="img-fluid img-thumbnail rounded float-left mr-4" style={{ height: '250px' }} />
					<h1>Gregory White</h1>

					<p>Greg is owner of Russo, White &amp; Keller, P.C.&nbsp; He joined the Firm in 2000.&nbsp; He currently handles cases in divorce (both uncontested and contested), custody disputes, child support, criminal defense, and personal injury.</p>
					<p>Greg was graduated from Florida State University in 1979 with a Bachelor of Science.&nbsp; In 1991, he received his Juris Doctor degree from Cumberland School of Law.</p>
					<p>Greg is licensed to practice in all courts in Alabama and in the United States District Court for the Northern District of Alabama.&nbsp; He is a member of the Alabama State Bar and the Birmingham Bar Association.</p>
					<p>Greg has been married to the same beautiful woman for 30 years with whom he has two wonderful children.&nbsp; Greg is a member and regular attender of Mountain Top Community Church in Vestavia Hills, Alabama.</p>

				</div>

				<div className="col">
					<ContactUsForm />
				</div>
			</div>
		</div>
	</Layout>
)